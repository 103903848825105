import React from 'react';

import Layout from '../components/Layout';

export default ({}) => (
  <Layout>
    <>
      <strong>Oops</strong>. Page not found. 🤷‍♂️
    </>
  </Layout>
);
